class ZIDXAccountAutoresponderEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountAutoresponderEditContainer";
    }
    getMatchURL(){
        return "/account/autoresponders/edit";
    }
    render(){
        // console.log("edit autoresponder");
    }
}